import React, { useState, useEffect, useRef } from 'react'
import { database, auth } from './firebase'
import { useParams, useNavigate } from 'react-router-dom'
import { ref, set, onValue, get, remove } from 'firebase/database'
import './styled-table.css'
import { GoogleAuthProvider } from 'firebase/auth'
// import { signInWithPopup } from 'firebase/auth'
import { signInWithRedirect } from 'firebase/auth'
import { GoogleButton } from 'react-google-button'
import { Link } from 'react-router-dom'
import {
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged
} from 'firebase/auth'
import callOpenAI from './openai'

import SegmentedControl from './SegmentedControl'
import LoadingOverlay from './LoadingOverlay'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { SHA256 } from 'crypto-js'
import { encryptWithPrivateKey, decryptWithPrivateKey } from './encryption'

import { gapi } from 'gapi-script'

// const plainText = 'Greg & Michelle Hoagland'
// const encrypted = encryptWithPrivateKey(plainText)
// const decrypted = decryptWithPrivateKey(encrypted)
// console.log('Encrypted:', encrypted)
// console.log('Decrypted:', decrypted)

// const provider = new GoogleAuthProvider()
const FindCommonTime = () => {
  const { meetingId } = useParams()
  const navigate = useNavigate()
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhotoURL, setUserPhotoURL] = useState('')
  const [emailArray, setEmailArray] = useState('')
  const [existingRecords, setExistingRecords] = useState({})
  const [meetingNotes, setMeetingNotes] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [suggestedSched, setSuggestedSched] = useState({
    'Scheduling conflicts': []
  })
  const [organizer, setOrganizer] = useState('')
  const [inputText, setInputText] = useState('')
  const [formattedStartDate, setFormattedStartDate] = useState('')
  const [selectedValue1, setSelectedValue1] = useState('Accept Suggested Date')
  const handleTextInputChange = e => {
    setInputText(e.target.value)
  }
  const handleTextSubmit = e => {
    handleSubmit(false, inputText)
  }
  const segment1Ref = useRef()
  const segment2Ref = useRef()
  const segDefaultRef = useRef()

  // Add/modify calendar
  useEffect(() => {
    function start () {
      gapi.client.init({
        clientId:
          '126476917756-vl2kbsr0p9resjv62017e4plc6ismhrc.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/calendar.events'
      })
    }
    gapi.load('client:auth2', start)
  }, [])

  const addEventToGoogleCalendar = () => {
    const event = {
      summary: 'Meeting Scheduled',
      location: 'Online',
      description: `Meeting with invitees: ${emailArray}`,
      start: {
        dateTime: `${formattedStartDate}T09:00:00-07:00`, // Example start time
        timeZone: 'America/Los_Angeles'
      },
      end: {
        dateTime: `${formattedStartDate}T10:00:00-07:00`, // Example end time
        timeZone: 'America/Los_Angeles'
      },
      attendees: emailArray.split(', ').map(email => ({ email }))
    }

    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        const request = gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: event
        })

        request.execute(event => {
          console.log('Event created: ', event.htmlLink)
          alert('Event added to Google Calendar')
        })
      })
  }

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        const userName_ = user.displayName || user.email
        if (user && userName === '') {
          setUserName(userName_)
          setUserEmail(user.email)
          setUserPhotoURL(user.photoURL)
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.User
          // console.log(user.displayName)
          // console.log(user.email)
        } else {
          // User is signed out
          console.log('User is signed out')
          console.log(user.email)
        }
      }
    })
  }, [userName]) // This effect runs whenever userName changes

  const sendEmail = () => {
    const meetingLink = `${window.location.origin}/meeting/${meetingId}`
    // Construct a single email message with all recipients
    const emailMessage = `Dear all,\n\nI just updated the invite link. Please tap on the link below to see the updates:\n ${meetingLink}  \n\n, ${userName}`

    // Create a mailto link to launch the user's default email client
    const mailtoLink = `mailto:${emailArray}?subject=CanYouMeet.us Invite Updated&body=${encodeURIComponent(
      emailMessage
    )}`

    // Open the default email client with the pre-filled message
    window.location.href = mailtoLink
  }

  const signInWithGoogle = () => {
    setPersistence(auth, browserLocalPersistence).then(() => {
      const provider = new GoogleAuthProvider()
      signInWithRedirect(auth, provider)
        .then(() => {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          // const token = credential.accessToken
          // The signed-in user info will be available after redirect.
        })
        .catch(error => {
          // Handle Errors here.
          const errorCode = error.code
          const errorMessage = error.message
          console.error(`Error: ${errorCode}, ${errorMessage}`)
        })
    })
  }

  function capitalizeFirstLetter (str) {
    return str.replace(/\b\w/g, char => char.toUpperCase())
  }

  // const handleDateTimeChange = date => {
  //   handleDateChange(date)
  //   const selectedTime_ = moment(date).format('MMMM Do YYYY, h:mm a')
  //   setSelectedTime(selectedTime_)
  //   console.log(moment(date).format('MMMM Do YYYY, h:mm a')) // Convert date to English string
  // }

  const handleDeleteUser = () => {
    const user_ref = ref(
      database,
      `meetings/${meetingId}/users/${capitalizeFirstLetter(userName)}`
    )
    remove(user_ref)
    console.log(`deleted userName = ${userName}`)
    // handleSubmit(true, selectedTime)
  }

  const changeToSuggested = () => {
    // setSelectedTime(suggestedSched['Suggested schedule'])
    console.log(
      `**** in changeToSuggested: ${suggestedSched['Suggested schedule']}`
    )
    handleSubmit(false, suggestedSched['Suggested schedule'])
  }

  useEffect(() => {
    if (meetingNotes.length > 0) {
      const meeting_ref = ref(database, `meetings/${meetingId}/notes`)
      set(meeting_ref, meetingNotes)
    }
  }, [meetingNotes, meetingId]) // This effect runs whenever meetingNotes changes

  // const changeNotes = () => {
  //   const meeting_ref = ref(database, `meetings/${meetingId}/notes`)
  //   set(meeting_ref, {
  //     meetingNotes
  //   })
  // }

  function hashFunction (input) {
    return SHA256(input).toString()
  }

  const handleSubmit = (shouldDelete = false, dateTime) => {
    console.log(
      `**** in handleSubmit: shouldDelete = ${shouldDelete}, dateTime = ${dateTime}`
    )

    setIsLoading(true)

    if (Object.keys(existingRecords).length === 0) {
      const organizerRef = ref(database, `meetings/${meetingId}/organizer`)
      set(organizerRef, encryptWithPrivateKey(capitalizeFirstLetter(userName)))
    }
    const hashedEmail = hashFunction(userEmail)

    if (!shouldDelete) {
      console.log(
        `adding userName = ${userName}, {timeSlot: ${dateTime}, email: ${userEmail}}`
      )
      const user_ref = ref(
        database,
        `meetings/${meetingId}/users/${hashedEmail}`
      )
      console.log('userPhotoURL: ', userPhotoURL)
      set(user_ref, {
        timeSlots: dateTime,
        userName: encryptWithPrivateKey(capitalizeFirstLetter(userName)),
        email: encryptWithPrivateKey(userEmail),
        photoURL: encryptWithPrivateKey(userPhotoURL)
      })
    }

    const email_ref = ref(database, `users/${hashedEmail}/${meetingId}`)
    get(email_ref).then(snapshot => {
      if (!snapshot.exists()) {
        set(email_ref, {
          // email: `${userEmail}`,
          notes: `${meetingNotes}`,
          meetingId: `${meetingId}`
        })
      }
    })

    const users_ref = ref(database, `meetings/${meetingId}/users`)
    get(users_ref).then(snapshot => {
      if (snapshot.exists()) {
        setExistingRecords(snapshot.val())
        const snapshotVal = snapshot.val()
        const gptInstructions = Object.keys(snapshotVal)
          .map(
            user =>
              `${decryptWithPrivateKey(
                snapshotVal[user].userName
              )}'s preferred dates/times are ${snapshotVal[user].timeSlots}`
          )
          .join(',\n')
        console.log(gptInstructions)
        callOpenAI(gptInstructions)
          .then(jsonResponse => {
            console.log('** FindCommonTimes jsonResponse: ', jsonResponse)

            setIsLoading(false)

            // Write the response to the Firebase database
            const suggested = 'Suggested Schedule'
            const combined_ref = ref(
              database,
              `meetings/${meetingId}/suggested/${suggested}`
            )
            set(combined_ref, { timeSlots: jsonResponse })

            // Create a new Date object for the current year
            console.log(formattedStartDate)
          })
          .catch(error => {
            console.error(
              'There was an error with the OpenAI API request:',
              error
            )
            setIsLoading(false)
          })
      }
    })
    navigate(`/meeting/${meetingId}`)
  }

  useEffect(() => {
    const users_ref = ref(database, `meetings/${meetingId}/users`)
    onValue(users_ref, snapshot => {
      if (snapshot.exists()) {
        setExistingRecords(snapshot.val())
        const snapshotVal = snapshot.val()
        const emailArray_ = Object.keys(snapshotVal)
          .map(user => `${decryptWithPrivateKey(snapshotVal[user].email)}`)
          .join(', ')
        setEmailArray(emailArray_)
      }
    })

    const notesRef = ref(database, `meetings/${meetingId}/notes`)
    onValue(notesRef, snapshot => {
      if (snapshot.exists()) {
        setMeetingNotes(snapshot.val())
      }
    })

    const orginierRef = ref(database, `meetings/${meetingId}/organizer`)
    onValue(orginierRef, snapshot => {
      if (snapshot.exists()) {
        setOrganizer(decryptWithPrivateKey(snapshot.val()))
      }
    })

    const result_ref = ref(database, `meetings/${meetingId}/suggested`)
    onValue(result_ref, snapshot => {
      if (snapshot.exists()) {
        setSuggestedSched(snapshot.val()['Suggested Schedule']['timeSlots'])

        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        // Set the start date to March 10 of the current year
        const month_ =
          snapshot.val()['Suggested Schedule']['timeSlots'][
            'Suggested schedule month'
          ] - 1
        const day_ =
          snapshot.val()['Suggested Schedule']['timeSlots'][
            'Suggested schedule date'
          ]
        if (
          Number.isInteger(day_) &&
          day_ !== null &&
          Number.isInteger(month_) &&
          month_ !== null
        ) {
          const startDate = new Date(currentYear, month_, day_) // Note: Months are 0-indexed in JavaScript, so 2 corresponds to March
          // Format the date as a string in the format "YYYY-MM-DD"
          if (startDate && startDate instanceof Date && !isNaN(startDate)) {
            setFormattedStartDate(startDate.toISOString().split('T')[0])
          }
        }
      }
    })
  }, [meetingId])

  const handleShare = async () => {
    const meetingLink = `${window.location.origin}/meeting/${meetingId}`

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Share this link',
          text: meetingLink,
          url: meetingLink
        })
        console.log('Sharing successful')
      } catch (err) {
        console.log('Sharing failed', err)
      }
    } else {
      console.log('Your browser does not support Web Share API')
      prompt('Share this link with other attendees:', meetingLink)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f2f2f2'
      }}
    >
      <Link to='/'>
        <div style={{ marginTop: '15px', borderRadius: '10px' }}>
          <img
            src={process.env.PUBLIC_URL + '/logo512.png'}
            alt='Logo'
            width='80'
          />
        </div>
      </Link>

      {/* <script
        type='module'
        src='https://unpkg.com/@splinetool/viewer/build/spline-viewer.js'
      ></script>
      <spline-viewer
        url='https://prod.spline.design/FVZWbQH2B6ndj9UU/scene.splinecode'
        events-target='global'
        style={{ width: '50%', height: '300px' }}
      ></spline-viewer> */}

      {((userName && userName !== organizer) || !userName) && (
        <div>
          {meetingNotes !== '' && (
            <h2 style={{ marginBottom: '20px' }}>{meetingNotes}</h2>
          )}
          {organizer !== '' && <h3>Organizer: {organizer}</h3>}
        </div>
      )}
      {userName && userName === organizer && (
        <>
          <h2 style={{ marginBottom: '20px' }}>Meeting Info</h2>
          <textarea
            value={meetingNotes}
            onChange={e => setMeetingNotes(e.target.value)}
            placeholder='Tell them what this event is about and at what time and where.'
            rows='3'
            style={{
              borderRadius: '10px',
              padding: '10px',
              width: window.innerWidth < 500 ? '85%' : '50%',
              marginBottom: '10px',
              fontSize: '20px',
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)'
            }}
          />
        </>
      )}
      {isLoading && <LoadingOverlay />}
      {!userName && (
        <div>
          <h3
            style={{
              display: 'flex',
              marginTop: '35px',
              color: 'gray',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '10px',
              justifyContent: 'center'
            }}
          >
            {Object.keys(existingRecords).length === 0
              ? 'Sign in to create event'
              : 'Sign in to respond'}
          </h3>
          <GoogleButton
            onClick={signInWithGoogle}
            style={{ marginBottom: '20px' }}
          />
        </div>
      )}

      {Object.keys(existingRecords).length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            borderRadius: '10px',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.25)',
            margin: '5px',
            width: window.innerWidth < 500 ? '85%' : '50%'
          }}
        >
          <table className='styled-table'>
            <thead>
              <tr>
                <th style={{ color: 'gray', width: '60px' }}></th>
                <th style={{ color: 'gray' }}>Name</th>
                <th style={{ color: 'gray' }}>Preference</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(existingRecords).map((user, index) => (
                <tr key={user} className={index % 2 === 0 ? 'even' : 'odd'}>
                  <td>
                    {existingRecords[user].photoURL && (
                      <img
                        src={decryptWithPrivateKey(
                          existingRecords[user].photoURL
                        )}
                        alt={`${user}'s`}
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%'
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {decryptWithPrivateKey(existingRecords[user].userName)}
                  </td>
                  <td>{existingRecords[user].timeSlots}</td>
                  {suggestedSched && (
                    <td>
                      {suggestedSched['Scheduling conflicts'] &&
                      suggestedSched['Scheduling conflicts'].includes(
                        decryptWithPrivateKey(existingRecords[user].userName)
                      )
                        ? '\u274C'
                        : '\u2714'}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!isLoading && Object.keys(existingRecords).length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px',
            borderRadius: '10px',
            // boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.25)',
            width: window.innerWidth < 500 ? '85%' : '50%',
            margin: '5px'
          }}
        >
          <table
            className='result-table'
            style={{ margin: '10px', borderSpacing: '10px' }}
          >
            <tbody>
              {Object.keys(suggestedSched)
                .filter(key => key === 'Suggested schedule')
                .map(key => (
                  <tr key={key}>
                    <td>
                      <b>Most Compatible Schedule</b>
                    </td>
                    <td>{suggestedSched[key]}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      <div>
        {userName && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '1px'
            }}
          >
            {/* <h3
              style={{ marginTop: '5px', marginBottom: '1px', color: 'gray' }}
            >
              {userName}
            </h3> */}
          </div>
        )}

        {userName && (
          <div
            className='container'
            style={{
              display: 'flex',
              justifyContent: 'center', // Centers horizontally
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
              borderRadius: '10px',
              boxShadow: userName
                ? '2px 2px 5px rgba(0, 0, 0, 0.25)'
                : '0px 0px 0px rgba(0, 0, 0, 0.25)',
              margin: '15px',
              border: '1px solid clear',
              height: '210px',
              marginBottom: '90px', // Added some space at the bottom
              // maxWidth: '500px', // Added to limit the maximum width
              // width: '99%' // Added to make it responsive
              width: window.innerWidth < 500 ? '95%' : '95%'
            }}
          >
            <h3
              style={{ marginTop: '15px', marginBottom: '1px', color: 'gray' }}
            >
              {userName}
            </h3>
            <SegmentedControl
              name='group-1'
              callback={val => setSelectedValue1(val)}
              controlRef={segDefaultRef}
              segments={
                Object.keys(existingRecords).length === 0
                  ? [
                      {
                        label: 'Enter Suggested Date',
                        value: 'Suggest New Date',
                        ref: segment2Ref
                      }
                    ]
                  : [
                      {
                        label: 'Accept Most Compatible',
                        value: 'Accept Suggested Date',
                        ref: segment1Ref
                      },
                      {
                        label: 'Suggest New Date',
                        value: 'Suggest New Date',
                        ref: segment2Ref
                      }
                    ]
              }
            />
            {selectedValue1 === 'Accept Suggested Date' &&
              Object.keys(existingRecords).length > 0 && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      // alignItems: 'center',
                      // marginTop: '10px',
                      justifyContent: 'center'
                    }}
                  >
                    <button
                      onClick={changeToSuggested}
                      style={{
                        backgroundColor: '#4370a678',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '30px',
                        fontSize: '15px',
                        color: 'white', // Set the foreground color to white
                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      Submit
                    </button>
                  </div>{' '}
                </>
              )}
            {(selectedValue1 === 'Suggest New Date' ||
              Object.keys(existingRecords).length === 0) && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // marginTop: '10px',
                    justifyContent: 'center'
                  }}
                >
                  <textarea
                    rows='3'
                    style={{
                      // backgroundColor: '#4370a678',
                      padding: '10px 20px',
                      border: 'none',
                      borderRadius: '10px',
                      fontSize: '15px',
                      color: 'black',
                      marginRight: '10px',
                      marginBottom: '20px',
                      // width: '280px',
                      boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
                      '::placeholder': { color: 'white' }
                    }}
                    value={inputText}
                    onChange={handleTextInputChange}
                    placeholder='Enter your preference (eg, Feb 12 or March 5)'
                  />
                  <button
                    onClick={handleTextSubmit}
                    style={{
                      backgroundColor: '#4370a678',
                      padding: '10px 20px',
                      border: 'none',
                      borderRadius: '30px',
                      color: 'white',
                      fontSize: '15px',
                      boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {Object.keys(existingRecords).length > 0 && (
        <div
          style={{
            position: 'fixed',
            top: '20px',
            left: '20px',
            zIndex: '999'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              backgroundColor: '#fff',
              borderRadius: '5px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
            }}
          >
            <div
              style={{
                padding: '10px',
                cursor: 'pointer',
                fontSize: '18px',
                fontWeight: 'bold'
              }}
              onClick={() => {
                const menu = document.getElementById('hamburger-menu')
                menu.style.display =
                  menu.style.display === 'none' ? 'block' : 'none'
              }}
            >
              &#9776;
            </div>
            <div
              id='hamburger-menu'
              style={{
                display: 'none',
                padding: '10px',
                backgroundColor: '#fff',
                borderRadius: '5px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
              }}
            >
              {userName &&
                userName !== organizer &&
                Object.keys(existingRecords).length > 1 &&
                Object.keys(existingRecords).includes(userName) && (
                  <div
                    style={{
                      cursor: 'pointer',
                      borderBottom: '1px solid #e0e0e0',
                      paddingBottom: '5px',
                      marginBottom: '5px'
                    }}
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to remove yourself from this event?'
                        )
                      ) {
                        handleDeleteUser()
                      }
                    }}
                  >
                    Remove Me
                  </div>
                )}
              {Object.keys(existingRecords).length > 0 &&
                userName === organizer && (
                  <div
                    style={{
                      cursor: 'pointer',
                      borderBottom: '1px solid #e0e0e0',
                      paddingBottom: '5px',
                      marginBottom: '5px'
                    }}
                    onClick={handleShare}
                  >
                    Share Invite Link
                  </div>
                )}
              {Object.keys(existingRecords).length > 0 && (
                <div
                  style={{
                    cursor: 'pointer',
                    borderBottom: '1px solid #e0e0e0',
                    paddingBottom: '5px',
                    marginBottom: '5px'
                  }}
                  onClick={sendEmail}
                >
                  Notify Others by Email
                </div>
              )}
              <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
                New Event
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 9999
        }}
      >
        <AddToCalendarButton
          name={meetingNotes}
          startDate={formattedStartDate}
          options={['Apple', 'Google', 'Yahoo', 'iCal']}
          timeZone='America/Los_Angeles'
          description={`Link to invite: ${window.location.origin}/meeting/${meetingId},\nInvitees: ${emailArray} (Copy this in the Invitee list if you want recipients to be invited)`}
        />
      </div>
      <button
        onClick={addEventToGoogleCalendar}
        style={{
          backgroundColor: '#4285F4',
          color: '#fff',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          fontSize: '15px',
          marginTop: '20px'
        }}
      >
        Add to Google Calendar
      </button>
    </div>
  )
}

export default FindCommonTime
